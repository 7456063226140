import React, { useState, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import VideoBox from "../../components/VideoBox/VideoBox";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/bootstrap-overrides.scss";
import "../../scss/global.scss";
import "../../scss/grunticon.scss";
import VideoImg from "../../images/video-bg.png";
import VideoTab from "../../images/video-tab.png";
import VideoDesk from "../../images/video-desk.png";
import { isDesktop } from "react-device-detect";

const imgUrls = {
  mob: VideoImg,
  tab: VideoTab,
  tabLg: VideoDesk,
};

// markup
const HomePage = (props) => {

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])

  return (
    <>
      <Banner
        title={props.title}
        multi_banner_title={props.multi_banner_title}
        search={props.search}
        reviews={props.reviews}
        overlaybanner={props.overlaybanner}
        buttons={props.buttons}
        pageTitle={props.pageTitle}
        bannerimage={props.bannerimage}
        imagetransforms={props.imagetransforms}
        id={props.id}
        content={props.content}
        layout={props.layout}
        page={props.page}
      />
      {isDesktop == true && renderComponent ? 
        <VideoBox
          title={props.title}
          imgUrls={imgUrls}
          localUrls={true}
          bannerimage={props.bannerimage}
          imagetransforms={props.imagetransforms}
          id={props.id}
          video={props.video}
        />
         :
         renderComponent && (
            <VideoBox
            title={props.title}
            imgUrls={imgUrls}
            localUrls={true}
            bannerimage={props.bannerimage}
            imagetransforms={props.imagetransforms}
            id={props.id}
            video={props.video}
          />
          )
        }

    </>
  )
};

export default HomePage;